import {
  Dispatch, SetStateAction, useEffect, useState
} from 'react';

/**
 * This hook is equivalent to useState except it updates the state when props change
 * @param prop
 */
function useStateFromProp<T>(prop: T) : [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(prop);
  useEffect(() => setState(prop), [prop]);
  return [state, setState];
}
export default useStateFromProp;

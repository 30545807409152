import React from 'react';

const ExtraUnselected = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="32" viewBox="0 0 35 32" aria-hidden>
    <defs>
      <filter id="b8hyllkkla" width="245%" height="245%" x="-72.5%" y="-57.5%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1" />
        <feOffset dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter2" />
        <feOffset dy="1" in="shadowSpreadOuter2" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation=".5" />
        <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2" />
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter3" />
        <feOffset dy="3" in="shadowSpreadOuter3" result="shadowOffsetOuter3" />
        <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="4" />
        <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3" />
        <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
      <path id="kdrlrd2rub" d="M11 26c5.523 0 10-4.477 10-10S16.523 6 11 6 1 10.477 1 16c0 4.997 3.665 9.138 8.454 9.881.504.078 1.02.119 1.546.119z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-209 -989) translate(209 989)">
          <rect width="35" height="16" y="8" fill="#CFCECC" rx="8" />
          <g>
            <use fill="#000" filter="url(#b8hyllkkla)" href="#kdrlrd2rub" />
            <path fill="#FFF" stroke="#131313" d="M11 5.5c2.9 0 5.524 1.175 7.425 3.075 1.9 1.9 3.075 4.526 3.075 7.425 0 2.9-1.175 5.524-3.075 7.425-1.9 1.9-4.526 3.075-7.425 3.075-.552 0-1.094-.043-1.623-.125-2.532-.393-4.765-1.69-6.359-3.552C1.448 20.987.5 18.605.5 16c0-2.9 1.175-5.524 3.075-7.425C5.475 6.675 8.101 5.5 11 5.5z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ExtraUnselected;

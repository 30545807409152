import { InputLabel, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import fontStyles from '@/common/fontStyles';
import OutlineTextInput from '@/common/OutlineTextInput';
import useStateFromProp from '@/common/useStateFromProp';
import { RootState } from '@/rootStateTypes';

const useStyles = makeStyles(() => ({
  inputLabel: {
    ...fontStyles.formFieldLabel,
    fontFamily: 'open_sans',
    fontWeight: 600,
    lineHeight: '19px',
    display: 'block',
    marginBottom: '8px'
  },
  helperText: {
    marginBottom: '-20px',
    paddingTop: '4px'
  }
}));

const DEFAULT_CHARACTER_LIMIT = 16;
const SUS_CHARACTER_LIMIT = 28;

/**
 * Removes all characters from the string, except:
 *  alphanumeric characters, spaces, commas, periods, apostrophes, and dashes
 *
 * @param text
 */
function stripInvalidCharacters(text: string) {
  return text.replace(/[^a-zA-Z0-9\s,.'-]/g, '');
}

interface RemainingCharactersInputProps {
  id: string;
  onChange: (input: string) => void;
  initialValue: string;
  label?: string;
  additionalHelperText?: string;
  name?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  register?: (name: string) => void;
}

const RemainingCharactersInput = ({
  id, onChange, label, initialValue, ariaLabel = '', autoFocus = true, additionalHelperText = '', ...props
} : RemainingCharactersInputProps): JSX.Element => {
  const classes = useStyles();
  const [value, setValue] = useStateFromProp<string>(initialValue);
  const [focus, setFocus] = useState<boolean>(false);
  const posType = useSelector((state: RootState) => state
    .domain.localization.localizedStoreDetails?.posType);
  const characterLimit = posType === 'SUS' ? SUS_CHARACTER_LIMIT : DEFAULT_CHARACTER_LIMIT;

  const onTextEdit = (newText: string) => {
    const validText = stripInvalidCharacters(newText);
    const trimmed = validText.slice(0, characterLimit);
    setValue(trimmed);
    onChange(trimmed);
  };

  const onTextInputBlur = () => setFocus(false);
  const onTextInputFocus = () => setFocus(true);

  const handleHelperText = () => (focus ? `${characterLimit - (value ?? '').length} characters remaining. ${additionalHelperText}` : undefined);
  return (
    <>
      { label && (
        <InputLabel className={classes.inputLabel} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <OutlineTextInput
        id={id}
        testId={id}
        onChange={onTextEdit}
        autoComplete="off"
        value={value}
        onFocus={onTextInputFocus}
        onBlur={onTextInputBlur}
        helperTextClassName={classes.helperText}
        helperText={handleHelperText()}
        autoFocus={autoFocus}
        ariaLabel={ariaLabel}
        {...props}
      />
    </>
  );
};

export default RemainingCharactersInput;

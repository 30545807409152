import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';

interface HeaderTextProps {
  text?: string | null;
  testId?: string;
  margin?: string;
}

const useStyles = (margin?: string) => makeStyles((theme) => ({
  heading: {
    alignSelf: 'flex-end',
    margin: margin ?? 0
  }
}))();

const HeaderText = ({ testId, text, margin }: HeaderTextProps) => {
  const { heading } = useStyles(margin);
  return (
    <header data-testid={testId} className={heading}>
      <Typography variant="h1">{text}</Typography>
    </header>
  );
};

export default HeaderText;